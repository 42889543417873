<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    :title="title"
    @close="onCloseModal">
    <div>
      <div
        class="call-select-modal__wrapper call-input">
        <date-picker
          v-model="$v.dataForm.execute_before.$model"
          type="datetime"
          confirm
          format="DD MMM YYYY HH:mm"
          :clearable="false"
          :default-value="new Date().setHours(0, 0, 0, 0)"
          :disabled-date="notBeforeToday"
          placeholder="Time Schedule" />
        <label>Time Schedule</label>
      </div>
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataRefreshIcon"
        type="success"
        :disabled="$v.$invalid"
        @click="clickScheduleTask()">
        {{ computedIsTimeSchedule ? 'Add Schedule' : 'Update Schedule' }}
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import CallButton from '../CallButton';
import { BModal } from 'bootstrap-vue';
import iconsModal from '../../../mixins/iconsModal';
import { TASKS_UPDATE } from '../../../store/types/actions.types';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import helper from '../../../service/helper';

export default {
  name: 'CallReSheduled',
  components: {
    CallButton,
    BModal
  },
  mixins: [validationMixin, iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    timeSchedule: {
      type: [Number, String],
      default: null
    },
    title: {
      type: String,
      default: 'Re Schedule'
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataForm: {
        execute_before: null
      }
    };
  },
  validations: {
    dataForm: {
      execute_before: {
        required
      }
    }
  },
  computed: {
    computedIsTimeSchedule () {
      return helper.isEmpty(this.timeSchedule);
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        if (helper.isEmpty(this.timeSchedule)) {
          this.dataForm.execute_before = new Date();
        } else {
          this.dataForm.execute_before = new Date(new Date(this.timeSchedule).getTime() + (3600000 * (-(new Date().getTimezoneOffset() / 60)).toFixed(0)));
        }
      }
    }
  },
  methods: {
    notBeforeToday (date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickScheduleTask () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`task/${TASKS_UPDATE}`, {
          taskId: this.taskId,
          params: {
            execute_before: this.dataForm.execute_before
          },
          context: this
        });
        this.$emit('on-update');
        this.$Notify({
          title: 'Schedule',
          message: 'Successfully updated',
          type: 'success'
        });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
