<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Add Issues"
    @close="onCloseModal">
    <div>
      <div
        :class="['call-select-modal__wrapper call-input call-select-modal__wrapper--template', $v.dataCategoryId.$error && 'error-valide']">
        <v-select
          v-model="$v.dataCategoryId.$model"
          :options="dataSelectOptionsIssuesCategories"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Category</label>
      </div>
      <div
        :class="['call-select-modal__wrapper call-input call-select-modal__wrapper--template', $v.dataForm.issue_id.$error && 'error-valide']">
        <v-select
          v-model="$v.dataForm.issue_id.$model"
          :options="dataSelectOptionsIssues"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Issue</label>
      </div>
      <div
        :class="['call-select-modal__wrapper call-input', $v.dataForm.comment.$error && 'error-valide']">
        <b-form-textarea
          v-model="$v.dataForm.comment.$model"
          placeholder="Your comment..."
          rows="3"
          max-rows="6" />
      </div>
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        type="success"
        :disabled="$v.$invalid"
        @click="clickAddComment()">
        Add Issues
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import CallButton from '../CallButton';
import { BFormTextarea, BModal } from 'bootstrap-vue';
import iconsModal from '../../../mixins/iconsModal';
import { CALL_UPDATE, ISSUES_GET, ISSUES_GET_CATEGORIES } from '../../../store/types/actions.types';
import { validationMixin } from 'vuelidate';
import selectSmsTemplate from '@/mixins/select/selectSmsTemplate';
import selectIssuesCategories from '@/mixins/select/selectIssuesCategories';
import helper from '@/service/helper';
import { maxLength } from 'vuelidate/lib/validators';

export default {
  name: 'CallAddPhoneIssues',
  components: {
    CallButton,
    BModal,
    BFormTextarea
  },
  mixins: [validationMixin, iconsModal, selectSmsTemplate, selectIssuesCategories],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    teamListId: {
      type: Array,
      default: () => ([])
    },
    callId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataSelectOptionsIssues: [],
      dataCategoryId: {},
      dataForm: {
        comment: null,
        issue_id: {}
      }
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        this.$v.$reset();

        const [issuesCategories = []] = await Promise.all([
          this.$store.dispatch(`issues/${ISSUES_GET_CATEGORIES}`, {})
        ]);

        this.dataSelectOptionsIssuesCategories = issuesCategories;
      }
    },
    'dataCategoryId' () {
      this.dataForm.issue_id = {};
      this.$_ajaxIssues();
    }
  },
  validations: {
    dataCategoryId: {
      required: function (val) {
        return val != null && Object.keys(val).length > 0;
      }
    },
    dataForm: {
      issue_id: {
        required: function (val) {
          return val != null && Object.keys(val).length > 0;
        }
      },
      comment: {
        maxLength: maxLength(255)
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async $_ajaxIssues () {
      const { payload: { list = [] } } = await this.$store.dispatch(`issues/${ISSUES_GET}`, {
        params: {
          category_ids: [this.dataCategoryId?.id],
          team_ids: this.teamListId
        }
      });

      this.dataSelectOptionsIssues = list;
    },
    async clickAddComment () {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return false;
      }
      try {
        await this.$store.dispatch(`call/${CALL_UPDATE}`, {
          params: helper.clearObject({
            issue_id: this.dataForm.issue_id?.id ?? null,
            issue_comment: this.dataForm.comment
          }),
          callId: this.callId
        });
        this.$emit('on-update');
        this.$Notify({
          title: 'Issues',
          message: 'Successfully added',
          type: 'success'
        });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
