<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    :title="title"
    @close="onCloseModal">
    <div>
      <div
        :class="['call-select-modal__wrapper', 'call-input',
                 !dataForm.operator_id ? 'call-select--empty' : null]">
        <v-select
          v-model="dataForm.operator_id"
          :options="dataSelectOptionsOperator"
          label="name"
          :components="{Deselect: dataComponentDeselect}"
          placeholder="Operators"
          class="call-select-modal call-select">
          <template #open-indicator="{ attributes }">
            <span v-bind="attributes">
              <font-awesome-icon :icon="dataIconDown" />
            </span>
          </template>
          <template slot="no-options">
            Введите имя оператора
          </template>
          <template
            slot="option"
            slot-scope="option">
            <div class="d-center">
              {{ option.name }}
            </div>
          </template>
        </v-select>
        <label>Operators</label>
      </div>
    </div>
    <template v-slot:modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataAddIcon"
        type="success"
        :disabled="!computedCacheDisabled"
        @click="clickOperatorTask()">
        {{ computedIsOperatorId ? 'Add Operator' : 'Update Operator' }}
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import CallButton from '../CallButton';
import { BModal } from 'bootstrap-vue';
import iconsModal from '../../../mixins/iconsModal';
import { TASKS_UPDATE, USERS_GET_ALL } from '../../../store/types/actions.types';
import selectOperators from '../../../mixins/select/selectOperators';
import { ID_ROLE_OPERATOR } from '../../../service/consts';
import helper from '../../../service/helper';

export default {
  name: 'CallReAssign',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal, selectOperators],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    taskId: {
      type: [Number, String],
      default: null
    },
    teamId: {
      type: [Number, String],
      default: null
    },
    operatorId: {
      type: [Number, String],
      default: null
    },
    title: {
      type: String,
      default: 'Re Assign'
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataFormOld: {},
      dataForm: {
        operator_id: null
      }
    };
  },
  computed: {
    computedIsOperatorId () {
      return helper.isEmpty(this.operatorId);
    },
    computedCacheDisabled () {
      return JSON.stringify(this.dataForm) !== JSON.stringify(this.dataFormOld);
    }
  },
  watch: {
    async value () {
      this.dataModal = this.value;
      if (this.dataModal) {
        Object.assign(this.$data, this.$options.data.apply(this));
        console.log({
          role: ID_ROLE_OPERATOR,
          team_ids: [this.teamId],
          short: true
        });
        const { payload = {} } = await this.$store.dispatch(`user/${USERS_GET_ALL}`, {
          params: {
            role: ID_ROLE_OPERATOR,
            team_ids: [this.teamId],
            short: true,
            without_blocked: true
          }
        });
        this.dataSelectOptionsOperator = payload?.list || [];
        this.dataForm.operator_id = this.dataSelectOptionsOperator.find(item => item.id === this.operatorId);
        this.dataFormOld.operator_id = this.dataSelectOptionsOperator.find(item => item.id === this.operatorId);
      }
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickOperatorTask () {
      try {
        await this.$store.dispatch(`task/${TASKS_UPDATE}`, {
          taskId: this.taskId,
          params: {
            operator_id: this.dataForm.operator_id?.id || null
          },
          context: this
        });
        this.$emit('on-update');
        this.$Notify({
          title: 'Operator',
          message: 'Successfully updated',
          type: 'success'
        });
        this.onCloseModal();
      } catch (e) {
        throw Error(e);
      }
    }
  }
};
</script>
