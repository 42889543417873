import { faAddressCard, faCheck, faClock, faComment, faGift, faPhone, faUser, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CallButton from '../CallButton';
import CallBadgeTable from '../CallBadgeTable';
import { mapGetters } from 'vuex';
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'CallCardTasksDetail',
  components: {
    CallButton,
    CallBadgeTable,
    BBadge
  },
  props: {
    objCard: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      dataIconUser: faUser,
      dataIconClock: faClock,
      dataIconComment: faComment,
      dataIconGift: faGift,
      dataIconCheck: faCheck,
      dataIconPhone: faPhone,
      dataIconAdress: faAddressCard,
      dataIconCheckCircle: faCheckCircle
    };
  },
  computed: {
    ...mapGetters({
      computedIsAdmin: 'auth/isAdmin',
      computedIsOperator: 'auth/isOperator'
    })
  },
  render (h) {
    return (
      <div class="card-project">
        <div class={`card-project__header card-project__header--full ${this.objCard?.closed_by && 'card-project__header--align-right'}`}>
          {
            // eslint-disable-next-line camelcase
            this.objCard?.closed_by
              ? [
                <span class="card-project__closed-label">
                  {this.objCard?.closing_type} Closed
                  <font-awesome-icon class="success ml-1" icon={this.dataIconCheckCircle}/>
                </span>
                ]
              : [
                // eslint-disable-next-line camelcase
                <call-button plain type="warning" icon={this.dataIconUser} onClick={() => this.$emit('on-actions', 're-assign')}>&nbsp;{this.objCard?.operator_id ? 'Re-Assign' : 'Assign'}</call-button>,
                // eslint-disable-next-line camelcase
                <call-button plain type="warning" icon={this.dataIconClock} onClick={() => this.$emit('on-actions', 're-shedule')}>&nbsp;{this.objCard?.execute_before ? 'Re-Schedule' : 'Schedule'}</call-button>,
                /*     <call-button plain type="success" icon={this.dataIconComment} style={{ opacity: 0 }} onClick={() => this.$emit('on-actions', 'sms')}>&nbsp;SMS</call-button>,
                <call-button plain type="success" icon={this.dataIconGift} style={{ opacity: 0 }} onClick={() => this.$emit('on-actions', 'gift')}>&nbsp;Gift</call-button>, */
                <call-button plain type="success" icon={this.dataIconCheck} onClick={() => this.$emit('on-actions', 'close')}>&nbsp;Close</call-button>
                ]
          }
        </div>
        <div class="card-project__body">
          <p class="card-project__title">
            {/* eslint-disable-next-line camelcase */}
            Player ID: {this.objCard?.player_id || 0}
            {this.objCard?.vip && <b-badge
            variant="vip">
            VIP
          </b-badge>
          }
          {this.objCard?.available_bonus_after_call && !this.objCard?.available_bonus && <b-badge variant="vip"><font-awesome-icon
                        icon={this.dataIconGift} /></b-badge>}

          </p>
          {
            this.computedIsOperator && [
              this.objCard?.player_name &&
              <p class="card-project__info">
                <font-awesome-icon
                  icon={this.dataIconAdress}/>
                {/* eslint-disable-next-line camelcase */}
                &nbsp;{this.objCard?.player_name || ''}
              </p>,
              // eslint-disable-next-line camelcase
              this.objCard?.player_birthday &&
                 <p class="card-project__info">
                  <font-awesome-icon
                    icon={this.dataIconGift}/>
                  {/* eslint-disable-next-line camelcase */}
                &nbsp;{this.$options.filters.dateBrithday(this.objCard?.player_birthday)}
                </p>
            ]
          }
          {
              this.objCard?.player_phone &&
              <p class="card-project__info">
                <font-awesome-icon
                    icon={this.dataIconPhone}/>
                {/* eslint-disable-next-line camelcase */}
                &nbsp;{this.objCard?.player_phone || ''}
              </p>
          }
        </div>
        <div class="card-project__footer project-card__footer--player">
          {
            // eslint-disable-next-line camelcase
            this.objCard?.execute_before
              ? <call-badge-table type="warning" icon={this.dataIconClock}>
                {/* eslint-disable-next-line camelcase */}
                {this.$options.filters.dateFilter(this.objCard?.execute_before) || ''}
              </call-badge-table> : null
          }
          {
            // eslint-disable-next-line camelcase
            this.objCard?.operator_name
              ? <call-badge-table type="success" icon={this.dataIconUser}>
                {/* eslint-disable-next-line camelcase */}
                {this.objCard?.operator_name || ''}
              </call-badge-table> : null
          }
        </div>
      </div>
    );
  }
};
