<template>
  <div>
    <call-card-tasks-detail
      :obj-card="dataTaskDetail"
      @on-actions="onActionButton" />
    <call-time-line
      :events="dataTaskDetail.events"
      @on-add-issues="onAddIssue"
      @on-comment="onActionButtonComment" />
    <call-button
      v-if="dataTaskDetail && dataTaskDetail.closed_by === null"
      type="success"
      plain
      :icon="dataIconAddComment"
      @click="dataShowAddComment = true;">
      Add Comment
    </call-button>
    <call-re-assign
      v-model="dataShowReAssign"
      :team-id="computedProjectTeamId"
      :title="dataModalTitle"
      :operator-id="dataTaskDetail.operator_id"
      :task-id="computedTaskId"
      @on-update="onUpdate" />
    <call-re-sheduled
      v-model="dataShowReSchedule"
      :title="dataModalTitle"
      :time-schedule="dataTaskDetail.execute_before"
      :task-id="computedTaskId"
      @on-update="onUpdate" />
    <call-close-task
      v-model="dataShowCloseTask"
      :task-id="computedTaskId"
      @on-update="onUpdate" />
    <call-add-comment
      v-model="dataShowAddComment"
      :task-id="computedTaskId"
      @on-update="onUpdate" />
    <call-delete-comment
      v-model="dataShowDeleteComment"
      :task-id="computedTaskId"
      :comment-id="dataPropsComment.id"
      @on-update="onUpdate" />
    <call-edit-comment
      v-model="dataShowEditComment"
      :task-id="computedTaskId"
      :obj-comment="dataPropsComment"
      @on-update="onUpdate" />
    <call-add-phone-issues
      v-model="dataShowAddIssue"
      :team-list-id="computedTeamsIds"
      :call-id="dataPropsCallId"
      @on-update="onUpdate" />
    <call-edit-phone-issues
      v-model="dataShowEditIssue"
      :team-list-id="computedTeamsIds"
      :obj-issues="dataPropsEditIssue"
      @on-update="onUpdate" />
    <call-delete-phone-issues
      v-model="dataShowDeleteIssue"
      :call-id="dataPropsEditIssue.call_id"
      @on-update="onUpdate" />
  </div>
</template>

<script>
import CallCardTasksDetail from '../../../components/common/cards/tasksDetailCard';
import { PROJECTS_GET, TASKS_GET } from '../../../store/types/actions.types';
import CallTimeLine from '../../../components/common/CallTimeline';
import CallButton from '../../../components/common/CallButton';
import { faCommentMedical } from '@fortawesome/free-solid-svg-icons';
import CallReAssign from '../../../components/common/modals/CallReAssign';
import CallReSheduled from '../../../components/common/modals/CallReSheduled';
import CallCloseTask from '../../../components/common/modals/CallCloseTask';
import CallAddComment from '../../../components/common/modals/CallAddComment';
import CallDeleteComment from '../../../components/common/modals/CallDeleteComment';
import CallEditComment from '../../../components/common/modals/CallEditComment';
import helper from '../../../service/helper';
import CallAddPhoneIssues from '@/components/common/modals/CallAddPhoneIssues';
import CallEditPhoneIssues from '@/components/common/modals/CallEditPhoneIssues';
import CallDeletePhoneIssues from '@/components/common/modals/CallDeletePhoneIssues';
import { mapGetters } from 'vuex';

export default {
  name: 'TasksDetail',
  components: { CallDeletePhoneIssues, CallEditPhoneIssues, CallAddPhoneIssues, CallEditComment, CallDeleteComment, CallAddComment, CallCloseTask, CallReSheduled, CallReAssign, CallButton, CallTimeLine, CallCardTasksDetail },
  data () {
    return {
      dataTaskDetail: {},
      dataProjectOptions: {},
      dataIconAddComment: faCommentMedical,
      dataShowReAssign: false,
      dataShowReSchedule: false,
      dataShowCloseTask: false,
      dataShowAddComment: false,
      dataShowDeleteComment: false,
      dataShowEditComment: false,
      dataShowAddIssue: false,
      dataShowEditIssue: false,
      dataShowDeleteIssue: false,
      dataPropsCallId: null,
      dataPropsEditIssue: {},
      dataPropsComment: {},
      dataModalTitle: null
    };
  },
  computed: {
    ...mapGetters({
      computedStoreCurrentUser: 'auth/currentUser'
    }),
    computedProjectId () {
      return this.$route.params?.projectId;
    },
    computedTaskId () {
      return this.$route.params?.taskId;
    },
    computedProjectTeamId () {
      // eslint-disable-next-line camelcase
      return this.dataProjectOptions?.team_id;
    },
    computedTeamsIds () {
      return this.computedStoreCurrentUser.teams.map(item => item.id);
    }
  },
  async mounted () {
    const [{ project = {} }] = await Promise.all([
      this.$store.dispatch(`project/${PROJECTS_GET}`, {
        projectId: this.computedProjectId
      }),
      this.onUpdate()
    ]);
    this.dataProjectOptions = project;

    if (helper.isEmpty(this.$route, 'params.query.projectName')) {
      this.$router.replace({
        name: 'ProjectTasksDetail',
        params: {
          ...this.$route.params
        },
        query: {
          ...this.$route.query,
          projectName: project.name
        }
      }, () => {
        // this.$router.go(0);
      });
    }
  },
  methods: {
    async onUpdate () {
      const { task = {} } = await this.$store.dispatch(`task/${TASKS_GET}`, {
        params: {
          id: this.computedTaskId
        }
      });
      this.dataTaskDetail = task;
    },
    onAddIssue ({ id: callId }) {
      this.dataPropsCallId = callId;
      this.dataShowAddIssue = true;
    },
    onActionButton (step) {
      switch (step) {
        case 're-assign':
          this.dataModalTitle = helper.isEmpty(this.dataTaskDetail, 'operator_id') ? 'Assign' : 'Re Assign';
          this.dataShowReAssign = true;
          break;
        case 're-shedule':
          this.dataModalTitle = helper.isEmpty(this.dataTaskDetail, 'execute_before') ? 'Schedule' : 'Re Schedule';
          this.dataShowReSchedule = true;
          break;
        case 'sms':
          break;
        case 'gift':
          break;
        case 'close':
          this.dataShowCloseTask = true;
          break;
      }
    },
    onActionButtonComment (data) {
      if (data.call_id) {
        this.dataPropsEditIssue = data;
        switch (data.type) {
          case 'edit':
            this.dataShowEditIssue = true;
            break;
          case 'delete':
            this.dataShowDeleteIssue = true;
            break;
        }
      } else {
        this.dataPropsComment = data;
        switch (data.type) {
          case 'edit':
            this.dataShowEditComment = true;
            break;
          case 'delete':
            this.dataShowDeleteComment = true;
            break;
        }
      }
    }
  }
};
</script>
