<template>
  <b-modal
    v-model="dataModal"
    centered
    no-close-on-backdrop
    no-close-on-esc
    title="Issues Comment"
    @close="onCloseModal">
    <div>
      Are you sure you want to delete the issues ?
    </div>
    <template #modal-footer>
      <call-button
        :icon="dataCloseIcon"
        type="danger"
        plain
        @click="onCloseModal()">
        Cancel
      </call-button>
      <call-button
        :icon="dataIconTrash"
        type="success"
        @click="clickDelete()">
        Delete
      </call-button>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue';
import CallButton from '../CallButton';
import { CALL_UPDATE } from '../../../store/types/actions.types';
import iconsModal from '../../../mixins/iconsModal';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Notify } from 'vant';

export default {
  name: 'CallDeletePhoneIssues',
  components: {
    CallButton,
    BModal
  },
  mixins: [iconsModal],
  props: {
    value: {
      type: Boolean,
      default: false
    },
    callId: {
      type: [Number, String],
      default: null
    }
  },
  data () {
    return {
      dataModal: this.value,
      dataIconTrash: faTrashAlt
    };
  },
  watch: {
    async value () {
      this.dataModal = this.value;
    }
  },
  methods: {
    onCloseModal () {
      this.$emit('input', false);
    },
    async clickDelete () {
      try {
        await this.$store.dispatch(`call/${CALL_UPDATE}`, {
          params: {
            issue_id: null,
            issue_comment: null
          },
          callId: this.callId
        });
        if (this.$device.mobile) {
          Notify({ type: 'success', message: 'Issues successfully deleted' });
        } else {
          this.$Notify({
            title: 'Issues',
            message: 'Successfully deleted',
            type: 'success'
          });
        }
        this.onCloseModal();
        this.$emit('on-update');
      } catch (e) {
        throw Error('Ошибка блокировки');
      }
    }
  }
};
</script>
